import * as React from 'react';
import NorthAmericaBasePageLayout from "../../layouts/NorthAmericaBasePageLayout";
import Grid from "../../components/soul/grid/Grid";
import {GridDirection} from "../../components/soul/grid/GridDirection";
import GridItem from "../../components/soul/grid/GridItem";
import SLink from "../../components/soul/link/SLink";
import {AnchorType} from "../../components/soul/link/LinkTypes";
import {Icons} from "../../components/soul/icon/IconsSet";
import {GridDensity} from "../../components/soul/grid/GridDensity";
import {fullSpanOnMobile, hideSpanOnDesktop, hideSpanOnMobile} from "../../components/soul/grid/GridItemSpan";
import {Section} from "../../components/Section";
import {StaticImage} from "gatsby-plugin-image";
import {GHAlignment, GVAlignment} from "../../components/soul/grid/GridAlignment";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const IndexPage: React.FC = () => {
    return (
        <NorthAmericaBasePageLayout location="/north-america">
            <Section>
                <Grid direction={GridDirection.HORIZONTAL} wrap={true} hAlignment={GHAlignment.START} vAlignment={GVAlignment.START} density={GridDensity.COMFORTABLE}>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <div className="soul-content soul-space-stack-bottom-l">
                            <h2 className="soul-font-size-xl">Don’t miss the 8th biennial edition of ESTECO Users’ Meeting North America at Plymouth, MI, on 24 and 25 October, 2023.</h2>
                            <p>Join more than 100 modeFRONTIER and VOLTA enthusiasts to share their insights, experiences, and success stories, providing a unique perspective on the application of ESTECO Technology in diverse domains and industries.</p>
                            <p>Speakers from past editions have included representatives from companies such as Schlumberger, Raytheon Missiles and Defense, Ford Motor Company, Whirlpool Corporation, and many more.</p>
                        </div>
                        <SLink to="/north-america/agenda-um23-na.pdf" linkType={AnchorType.GHOST} icon={Icons.FORWARD} iconAfter={true} className="h-text-bold" attributes={{target: '_blank'}}>
                            The full agenda is available here.
                        </SLink>
                        <div className="soul-content soul-space-stack-both-l">
                            <p>Moreover, participants will have the opportunity to see VOLTA and modeFRONTIER in action, discuss the latest product features and technology trends, and ask questions directly to our experts.</p>
                        </div>
                        <SLink to="https://www.eventbrite.it/e/esteco-users-meeting-2023-north-america-tickets-658402217757" linkType={AnchorType.GHOST} icon={Icons.FORWARD} iconAfter={true} className="h-text-bold">
                            Reserve your seat. Register now
                        </SLink>
                    </GridItem>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <StaticImage src="../../assets/img/esteco_us_um.jpg" alt="Users' meeting NA" />
                    </GridItem>
                </Grid>
            </Section>
            <Section>
                <Grid direction={GridDirection.HORIZONTAL} wrap={true} hAlignment={GHAlignment.START} vAlignment={GVAlignment.START} density={GridDensity.COMFORTABLE}>
                    <GridItem colSpan={6} responsive={[hideSpanOnMobile]}>
                        <StaticImage src="../../assets/img/esteco_us_building.jpg" alt="ESTECO NA building" />
                    </GridItem>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <div className="soul-content">
                            <h2 className="soul-font-size-xl">Join the celebration for the 20th anniversary of ESTECO North America</h2>
                            <p>Year 2023 marks 20 years since the foundation of ESTECO North America. It was the year 2003 when our office in the United States was established, first in Miami and then in Novi, Michigan - the perfect location to support our optimization enthusiasts operating in the automotive industry.</p>
                            <p>Since then, our customer base has continued to grow and expanded to new industries and domains all over the country. This edition is the perfect opportunity to celebrate an important milestone with everyone who has contributed to it over all these years.</p>
                        </div>
                    </GridItem>
                    <GridItem colSpan={12} responsive={[hideSpanOnDesktop]}>
                        <StaticImage src="../../assets/img/esteco_us_building.jpg" alt="ESTECO NA building" />
                    </GridItem>
                </Grid>
            </Section>
            <Section>
                <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE} wrap={true}>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <h2 className="soul-font-size-xl soul-space-stack-bottom-s">Day One: 24 October</h2>
                        <p className="soul-space-stack-bottom-m">A rich agenda of technical presentations from industry and academic experts.</p>
                        <h2 className="soul-font-size-xl soul-space-stack-bottom-s">Day Two: 25 October</h2>
                        <p>Training and workshop sessions to get hands-on experience with ESTECO Technology.</p>
                    </GridItem>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <h2 className="soul-font-size-xl soul-space-stack-bottom-s">Reasons to join</h2>
                        <div className="soul-content">
                            <ul>
                                <li>Learn how modeFRONTIER and VOLTA are used to drive the simulation<br/>driven design process and enable the engineering digital thread.</li>
                                <li>Meet industry peers and ESTECO team members.</li>
                                <li>Check out the new features and functionalities.</li>
                                <li>Attend the training sessions for free.</li>
                            </ul>
                        </div>
                    </GridItem>
                </Grid>
            </Section>
            <Section>
                <a href="https://www.eventbrite.it/e/esteco-users-meeting-2023-north-america-tickets-658402217757" className="registration-banner">
                    <Grid direction={GridDirection.HORIZONTAL}>
                        <GridItem colSpan="fill" responsive={[hideSpanOnMobile]}>
                            <StaticImage src="../../assets/img/registerNowBannerNA.png" alt="Be sure to secure your seat. Register now" />
                        </GridItem>
                        <GridItem colSpan="fill" responsive={[hideSpanOnDesktop]}>
                            <StaticImage src="../../assets/img/registerNowBannerMobileNA.png" alt="Be sure to secure your seat. Register now" />
                        </GridItem>
                    </Grid>
                </a>
            </Section>
            <Section>
                <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE} wrap={true}>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <div className="soul-content">
                            <h2 className="soul-font-size-xl">The venue</h2>
                            <p className="soul-space-stack-both-m">An iconic landmark surrounded by greenery, the Saint John’s Resort is perfectly situated 24 minutes from the Detroit Metro Airport, Downtown Detroit and Ann Arbor.</p>
                            <p><span className="h-text-bold">Saint John’s Resort</span><br/>
                                44045 Five Mile Rd, Plymouth,<br/>
                                MI 48170, United States
                            </p>
                            <SLink to="https://www.saintjohnsresort.com/" linkType={AnchorType.DEFAULT} icon={Icons.FORWARD} iconAfter={true}>
                                Visit website
                            </SLink>
                        </div>
                    </GridItem>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <div className="esteco-full-width esteco-map-container" dangerouslySetInnerHTML={{
                            __html: `
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11786.262218253965!2d-83.4765148!3d42.3944006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824acf033a8ddbd%3A0x8b126caff36520c2!2sSaint%20John&#39;s%20Resort!5e0!3m2!1sit!2sit!4v1687766285707!5m2!1sit!2sit" width="600" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            `}}></div>
                    </GridItem>
                </Grid>
            </Section>
        </NorthAmericaBasePageLayout>
    );
};
export default IndexPage;
